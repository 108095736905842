.CounselList {
    width: 100%;
    border: 1px solid #EDEDED;
    border-radius: 10px;
    margin-bottom: 20px;
}
.CounselList .CounselList{
    margin: 10px 0;
}
.CounselList th {
    background-color: #EDEDED;
}
.CounselList tr {
    border: 1px solid #000;
}

.CounselList .BoardHead{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffedd4;
    font-weight: 600;

}
.CounselList .BoardHead01{
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    box-sizing: border-box;
}
.CounselList .BoardHead .BoardHead02{
    text-align: center;
}
.CounselList .BoardHead02{
    width: calc(100% - 450px);
    padding: 10px 20px;
    text-align: left;
    box-sizing: border-box;

    /* display: flex;
    align-items: center; */
}
.CounselList .BoardLink:hover {
    color: #ff9100;
}

.CounselList .BoardHead03{
    width: 100px;
    text-align: center;
    box-sizing: border-box;
}
.CounselList .BoardHead04{
    width: 150px;
    text-align: center;
    box-sizing: border-box;
}
.CounselList .BoardList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 7px 0;
    border-bottom: 1px solid #EDEDED;
}
.CounselList .BoardList:last-child {
    border-bottom: none;
}
@media (max-width: 768px) {
    .CounselList .BoardHead01 {
        display: none;
    }
    .BoardHead .BoardHead02 {
        text-align: center;
    }
    .CounselList .BoardHead02 {
        width: calc(100% - 120px);
    }
    .CounselList .BoardHead03 {
        width: 120px;
    }
    .CounselList .BoardHead04 {
        display: none;
    }
}