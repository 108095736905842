.Logout {
    width: 100%;
    padding: 15px 0;
    background-color: #ff9100;
    text-align: center;
    position: relative;
    color: #FFF;
}
.Logout h2 {
    font-size: 18px;
    font-weight: 600;
}
.Logout button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
    font-weight: 700;
    color: #FFF;
    cursor: pointer;
    border: 1px solid #FFF;
    padding: 5px 10px;
    border-radius: 5px;
}
.Logout button:hover {
    opacity: 0.7;
    transition: all 0.3s;
}
