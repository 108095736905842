.Profile {
    width: 100%;
    /* margin-top: 30px; */
    padding: 30px 0;
    display: flex;
    border-top: 1px solid #DBDBDB;
}

.Profile > div {
    width: 50%;
}
.Profile > div:nth-child(1) {
    text-align: left;
    padding: 0 10px;
}
.Profile .ProfileText01 {
    font-size: 21px;
    margin-bottom: 10px;
}
.Profile .ProfileText02 {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 10px;
}
.Profile .ProfileText03 {
    width: 100%;
    padding: 10px;
    text-align: center;
    background-color: #EDEDED;
    box-sizing: border-box;
    font-size: 12px;
    margin-bottom: 10px;
}
.ProfileBtn {
    display: block;
    text-align: center;
    width: 100%;
    padding: 15px 20px;
    background-color: #FF720A;
    color: #FFFFFF !important;
    box-sizing: border-box;
    border-radius: 50px;
    font-size: 18px;
}
.ProfileImg {
    float: right;
}
.ProfileImg img {
    width: 100%;
}