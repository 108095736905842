.SideBar {
    position: fixed;
    width: 300px;
    top: 0;
    left: -301px;
    height: 100%;
    background-color: #FFF;
    padding: 30px 20px;
    box-sizing: border-box;
    border-right: 1px solid #ededed;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999;
}
.SideBarActive {
    left: 0px;
    transition: all 0.3s;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
}
.SideBar .btnWrap {
    width: 100%;
    margin-bottom: 30px;
}
.SideBar .sideCloseBtn {
    color: #ff9100;
    float: right;
    font-size: 24px;
    font-weight: 800;
}
.SideBar .logo {
    width: 250px;
    margin-bottom: 50px;
}
.SideBar .menu {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    margin-bottom: 20px;
    margin-left: 10px;
}
.SideBar .borderTop {
    background: #EDEDED;
    border: none;
    height: 1px;
    width: 100%;
    margin: 20px 0 30px;
}
.SideBar .LinkIcon {
    font-size: 16px;
    margin-right: 10px;
}
.SideBar .counsel {
    margin-top: 30px;
    display: block;
    padding: 15px 0;
    width: 100%;
    font-size: 16px;
    font-weight: 600;
    background-color: #ff9100;
    color: #FFF;
    border-radius: 10px;
}
