.Postlist {
    width: 100%;
    border: 1px solid #EDEDED;
    border-radius: 10px;
    margin-bottom: 20px;
}
.Postlist .postList{
    margin: 10px 0;
}
.Postlist th {
    background-color: #EDEDED;
}
.Postlist tr {
    border: 1px solid #000;
}

.Postlist .BoardHead{
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background-color: #ffedd4;
    font-weight: 600;

}
.Postlist .BoardHead01{
    padding: 10px 20px;
    width: 200px;
    text-align: center;
    box-sizing: border-box;
}
.Postlist .BoardHead .BoardHead02{
    text-align: center;
}
.Postlist .BoardHead02{
    width: calc(100% - 350px);
    padding: 10px 20px;
    text-align: left;
    box-sizing: border-box;
}
.Postlist .BoardLink:hover {
    color: #ff9100;
}

.Postlist .BoardHead03{
    width: 150px;
    text-align: center;
    box-sizing: border-box;
}
.Postlist .BoardList {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    box-sizing: border-box;
    padding: 7px 0;
    border-bottom: 1px solid #EDEDED;
}
.Postlist .BoardList:last-child {
    border-bottom: none;
}
@media (max-width:768px) {
    .Postlist .BoardHead01{
        display: none;
    }
    .Postlist .BoardHead02{
        width: calc(100% - 120px);
        padding: 10px 20px;
        text-align: left;
        box-sizing: border-box;
    }
    .Postlist .BoardHead03{
        width: 120px;
        text-align: center;
        box-sizing: border-box;
    }
}