 .ql-container {
    box-sizing: border-box !important;
    font-family: Helvetica, Arial, sans-serif !important;
    font-size: 13px !important;
    height: 100% !important;
    margin: 0px !important;
    position: relative !important;
  }
  .ql-container.ql-disabled .ql-tooltip {
    visibility: hidden !important;
  }
  .ql-container.ql-disabled .PostDocContents ul[data-checked] > li::before {
    pointer-events: none !important;
  }
  .ql-clipboard {
    left: -100000px !important;
    height: 1px !important;
    overflow-y: hidden !important;
    position: absolute !important;
    top: 50% !important;
  }
  .ql-clipboard p {
    margin: 0 !important;
    padding: 0 !important;
  }
  .PostDocContents {
    box-sizing: border-box !important;
    line-height: 1.42 !important;
    height: 100% !important;
    outline: none !important;
    overflow-y: auto !important;
    padding: 12px 15px !important;
    tab-size: 4 !important;
    -moz-tab-size: 4 !important;
    text-align: left !important;
    white-space: pre-wrap !important;
    word-wrap: break-word !important;
  }
  .PostDocContents > * {
    cursor: text !important;
  }
  .PostDocContents p,
  .PostDocContents ol,
  .PostDocContents ul,
  .PostDocContents pre,
  .PostDocContents blockquote,
  .PostDocContents h1,
  .PostDocContents h2,
  .PostDocContents h3,
  .PostDocContents h4,
  .PostDocContents h5,
  .PostDocContents h6 {
    margin: 0 !important;
    padding: 0 !important;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
  }
  .PostDocContents ol,
  .PostDocContents ul {
    padding-left: 1.5em !important;
  }
  .PostDocContents ol > li,
  .PostDocContents ul > li {
    list-style-type: none !important;
  }
  .PostDocContents ul > li::before {
    content: '\2022' !important;
  }
  .PostDocContents ul[data-checked=true],
  .PostDocContents ul[data-checked=false] {
    pointer-events: none !important;
  }
  .PostDocContents ul[data-checked=true] > li *,
  .PostDocContents ul[data-checked=false] > li * {
    pointer-events: all !important;
  }
  .PostDocContents ul[data-checked=true] > li::before,
  .PostDocContents ul[data-checked=false] > li::before {
    color: #777 !important;
    cursor: pointer !important;
    pointer-events: all !important;
  }
  .PostDocContents ul[data-checked=true] > li::before {
    content: '\2611' !important;
  }
  .PostDocContents ul[data-checked=false] > li::before {
    content: '\2610' !important;
  }
  .PostDocContents li::before {
    display: inline-block !important;
    white-space: nowrap !important;
    width: 1.2em !important;
  }
  .PostDocContents li:not(.ql-direction-rtl)::before {
    margin-left: -1.5em !important;
    margin-right: 0.3em !important;
    text-align: right !important;
  }
  .PostDocContents li.ql-direction-rtl::before {
    margin-left: 0.3em !important;
    margin-right: -1.5em !important;
  }
  .PostDocContents ol li:not(.ql-direction-rtl),
  .PostDocContents ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em !important;
  }
  .PostDocContents ol li.ql-direction-rtl,
  .PostDocContents ul li.ql-direction-rtl {
    padding-right: 1.5em !important;
  }
  .PostDocContents ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
    counter-increment: list-0 !important;
  }
  .PostDocContents ol li:before {
    content: counter(list-0, decimal) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-1 {
    counter-increment: list-1 !important;
  }
  .PostDocContents ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-2 {
    counter-increment: list-2 !important;
  }
  .PostDocContents ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-3 {
    counter-increment: list-3 !important;
  }
  .PostDocContents ol li.ql-indent-3:before {
    content: counter(list-3, decimal) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-4 {
    counter-increment: list-4 !important;
  }
  .PostDocContents ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-5 {
    counter-increment: list-5 !important;
  }
  .PostDocContents ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-6 {
    counter-increment: list-6 !important;
  }
  .PostDocContents ol li.ql-indent-6:before {
    content: counter(list-6, decimal) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-7 {
    counter-increment: list-7 !important;
  }
  .PostDocContents ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-7 {
    counter-reset: list-8 list-9 !important;
  }
  .PostDocContents ol li.ql-indent-8 {
    counter-increment: list-8 !important;
  }
  .PostDocContents ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) '. ' !important;
  }
  .PostDocContents ol li.ql-indent-8 {
    counter-reset: list-9 !important;
  }
  .PostDocContents ol li.ql-indent-9 {
    counter-increment: list-9 !important;
  }
  .PostDocContents ol li.ql-indent-9:before {
    content: counter(list-9, decimal) '. ' !important;
  }
  .PostDocContents .ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 3em !important;
  }
  .PostDocContents li.ql-indent-1:not(.ql-direction-rtl) {
    padding-left: 4.5em !important;
  }
  .PostDocContents .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em !important;
  }
  .PostDocContents li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em !important;
  }
  .PostDocContents .ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 6em !important;
  }
  .PostDocContents li.ql-indent-2:not(.ql-direction-rtl) {
    padding-left: 7.5em !important;
  }
  .PostDocContents .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em !important;
  }
  .PostDocContents li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em !important;
  }
  .PostDocContents .ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 9em !important;
  }
  .PostDocContents li.ql-indent-3:not(.ql-direction-rtl) {
    padding-left: 10.5em !important;
  }
  .PostDocContents .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em !important;
  }
  .PostDocContents li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em !important;
  }
  .PostDocContents .ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 12em !important;
  }
  .PostDocContents li.ql-indent-4:not(.ql-direction-rtl) {
    padding-left: 13.5em !important;
  }
  .PostDocContents .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em !important;
  }
  .PostDocContents li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em !important;
  }
  .PostDocContents .ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 15em !important;
  }
  .PostDocContents li.ql-indent-5:not(.ql-direction-rtl) {
    padding-left: 16.5em !important;
  }
  .PostDocContents .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em !important;
  }
  .PostDocContents li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em !important;
  }
  .PostDocContents .ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 18em !important;
  }
  .PostDocContents li.ql-indent-6:not(.ql-direction-rtl) {
    padding-left: 19.5em !important;
  }
  .PostDocContents .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em !important;
  }
  .PostDocContents li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em !important;
  }
  .PostDocContents .ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 21em !important;
  }
  .PostDocContents li.ql-indent-7:not(.ql-direction-rtl) {
    padding-left: 22.5em !important;
  }
  .PostDocContents .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em !important;
  }
  .PostDocContents li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em !important;
  }
  .PostDocContents .ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 24em !important;
  }
  .PostDocContents li.ql-indent-8:not(.ql-direction-rtl) {
    padding-left: 25.5em !important;
  }
  .PostDocContents .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em !important;
  }
  .PostDocContents li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em !important;
  }
  .PostDocContents .ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 27em !important;
  }
  .PostDocContents li.ql-indent-9:not(.ql-direction-rtl) {
    padding-left: 28.5em !important;
  }
  .PostDocContents .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em !important;
  }
  .PostDocContents li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em !important;
  }
  .PostDocContents .ql-video {
    display: block !important;
    max-width: 100% !important;
  }
  .PostDocContents .ql-video.ql-align-center {
    margin: 0 auto !important;
  }
  .PostDocContents .ql-video.ql-align-right {
    margin: 0 0 0 auto !important;
  }
  .PostDocContents .ql-bg-black {
    background-color: #000 !important;
  }
  .PostDocContents .ql-bg-red {
    background-color: #e60000 !important;
  }
  .PostDocContents .ql-bg-orange {
    background-color: #f90 !important;
  }
  .PostDocContents .ql-bg-yellow {
    background-color: #ff0 !important;
  }
  .PostDocContents .ql-bg-green {
    background-color: #008a00 !important;
  }
  .PostDocContents .ql-bg-blue {
    background-color: #06c !important;
  }
  .PostDocContents .ql-bg-purple {
    background-color: #93f !important;
  }
  .PostDocContents .ql-color-white {
    color: #fff !important;
  }
  .PostDocContents .ql-color-red {
    color: #e60000 !important;
  }
  .PostDocContents .ql-color-orange {
    color: #f90 !important;
  }
  .PostDocContents .ql-color-yellow {
    color: #ff0 !important;
  }
  .PostDocContents .ql-color-green {
    color: #008a00 !important;
  }
  .PostDocContents .ql-color-blue {
    color: #06c !important;
  }
  .PostDocContents .ql-color-purple {
    color: #93f !important;
  }
  .PostDocContents .ql-font-serif {
    font-family: Georgia, Times New Roman, serif !important;
  }
  .PostDocContents .ql-font-monospace {
    font-family: Monaco, Courier New, monospace !important;
  }
  .PostDocContents .ql-size-small {
    font-size: 0.75em !important;
  }
  .PostDocContents .ql-size-large {
    font-size: 1.5em !important;
  }
  .PostDocContents .ql-size-huge {
    font-size: 2.5em !important;
  }
  .PostDocContents .ql-direction-rtl {
    direction: rtl !important;
    text-align: inherit !important;
  }
  .PostDocContents .ql-align-center {
    text-align: center !important;
  }
  .PostDocContents .ql-align-justify {
    text-align: justify !important;
  }
  .PostDocContents .ql-align-right {
    text-align: right !important;
  }
  .PostDocContents.ql-blank::before {
    color: rgba(0,0,0,0.6) !important;
    content: attr(data-placeholder) !important;
    font-style: italic !important;
    left: 15px !important;
    pointer-events: none !important;
    position: absolute !important;
    right: 15px !important;
  }
  .ql-snow.ql-toolbar:after,
  .ql-snow .ql-toolbar:after {
    clear: both !important;
    content: '' !important;
    display: table !important;
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    background: none !important;
    border: none !important;
    cursor: pointer !important;
    display: inline-block !important;
    float: left !important;
    height: 24px !important;
    padding: 3px 5px !important;
    width: 28px !important;
  }
  .ql-snow.ql-toolbar button svg,
  .ql-snow .ql-toolbar button svg {
    float: left !important;
    height: 100% !important;
  }
  .ql-snow.ql-toolbar button:active:hover,
  .ql-snow .ql-toolbar button:active:hover {
    outline: none !important;
  }
  .ql-snow.ql-toolbar input.ql-image[type=file],
  .ql-snow .ql-toolbar input.ql-image[type=file] {
    display: none !important;
  }
  .ql-snow.ql-toolbar button:hover,
  .ql-snow .ql-toolbar button:hover,
  .ql-snow.ql-toolbar button:focus,
  .ql-snow .ql-toolbar button:focus,
  .ql-snow.ql-toolbar button.ql-active,
  .ql-snow .ql-toolbar button.ql-active,
  .ql-snow.ql-toolbar .ql-picker-label:hover,
  .ql-snow .ql-toolbar .ql-picker-label:hover,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active,
  .ql-snow.ql-toolbar .ql-picker-item:hover,
  .ql-snow .ql-toolbar .ql-picker-item:hover,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
    color: #06c !important;
  }
  .ql-snow.ql-toolbar button:hover .ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
  .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
    fill: #06c !important;
  }
  .ql-snow.ql-toolbar button:hover .ql-stroke,
  .ql-snow .ql-toolbar button:hover .ql-stroke,
  .ql-snow.ql-toolbar button:focus .ql-stroke,
  .ql-snow .ql-toolbar button:focus .ql-stroke,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
  .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
  .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
  .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
  .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
    stroke: #06c !important;
  }
  @media (pointer: coarse) {
    .ql-snow.ql-toolbar button:hover:not(.ql-active),
    .ql-snow .ql-toolbar button:hover:not(.ql-active) {
      color: #444 !important;
    }
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-fill,
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke.ql-fill {
      fill: #444 !important;
    }
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke,
    .ql-snow.ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter,
    .ql-snow .ql-toolbar button:hover:not(.ql-active) .ql-stroke-miter {
      stroke: #444 !important;
    }
  }
  .ql-snow {
    box-sizing: border-box !important;
  }
  .ql-snow * {
    box-sizing: border-box !important;
  }
  .ql-snow .ql-hidden {
    display: none !important;
  }
  .ql-snow .ql-out-bottom,
  .ql-snow .ql-out-top {
    visibility: hidden !important;
  }
  .ql-snow .ql-tooltip {
    position: absolute !important;
    transform: translateY(10px) !important;
  }
  .ql-snow .ql-tooltip a {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .ql-snow .ql-tooltip.ql-flip {
    transform: translateY(-10px) !important;
  }
  .ql-snow .ql-formats {
    display: inline-block !important;
    vertical-align: middle !important;
  }
  .ql-snow .ql-formats:after {
    clear: both !important;
    content: '' !important;
    display: table !important;
  }
  .ql-snow .ql-stroke {
    fill: none !important;
    stroke: #444 !important;
    stroke-linecap: round !important;
    stroke-linejoin: round !important;
    stroke-width: 2 !important;
  }
  .ql-snow .ql-stroke-miter {
    fill: none !important;
    stroke: #444 !important;
    stroke-miterlimit: 10 !important;
    stroke-width: 2 !important;
  }
  .ql-snow .ql-fill,
  .ql-snow .ql-stroke.ql-fill {
    fill: #444 !important;
  }
  .ql-snow .ql-empty {
    fill: none !important;
  }
  .ql-snow .ql-even {
    fill-rule: evenodd !important;
  }
  .ql-snow .ql-thin,
  .ql-snow .ql-stroke.ql-thin {
    stroke-width: 1 !important;
  }
  .ql-snow .ql-transparent {
    opacity: 0.4 !important;
  }
  .ql-snow .ql-direction svg:last-child {
    display: none !important;
  }
  .ql-snow .ql-direction.ql-active svg:last-child {
    display: inline !important;
  }
  .ql-snow .ql-direction.ql-active svg:first-child {
    display: none !important;
  }
  .ql-snow .PostDocContents h1 {
    font-size: 2em !important;
  }
  .ql-snow .PostDocContents h2 {
    font-size: 1.5em !important;
  }
  .ql-snow .PostDocContents h3 {
    font-size: 1.17em !important;
  }
  .ql-snow .PostDocContents h4 {
    font-size: 1em !important;
  }
  .ql-snow .PostDocContents h5 {
    font-size: 0.83em !important;
  }
  .ql-snow .PostDocContents h6 {
    font-size: 0.67em !important;
  }
  .ql-snow .PostDocContents a {
    text-decoration: underline !important;
  }
  .ql-snow .PostDocContents blockquote {
    border-left: 4px solid #ccc !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding-left: 16px !important;
  }
  .ql-snow .PostDocContents code,
  .ql-snow .PostDocContents pre {
    background-color: #f0f0f0 !important;
    border-radius: 3px !important;
  }
  .ql-snow .PostDocContents pre {
    white-space: pre-wrap !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
    padding: 5px 10px !important;
  }
  .ql-snow .PostDocContents code {
    font-size: 85% !important;
    padding: 2px 4px !important;
  }
  .ql-snow .PostDocContents pre.ql-syntax {
    background-color: #23241f !important;
    color: #f8f8f2 !important;
    overflow: visible !important;
  }
  .ql-snow .PostDocContents img {
    max-width: 100% !important;
  }
  .ql-snow .ql-picker {
    color: #444 !important;
    display: inline-block !important;
    float: left !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    height: 24px !important;
    position: relative !important;
    vertical-align: middle !important;
  }
  .ql-snow .ql-picker-label {
    cursor: pointer !important;
    display: inline-block !important;
    height: 100% !important;
    padding-left: 8px !important;
    padding-right: 2px !important;
    position: relative !important;
    width: 100% !important;
  }
  .ql-snow .ql-picker-label::before {
    display: inline-block !important;
    line-height: 22px !important;
  }
  .ql-snow .ql-picker-options {
    background-color: #fff !important;
    display: none !important;
    min-width: 100% !important;
    padding: 4px 8px !important;
    position: absolute !important;
    white-space: nowrap !important;
  }
  .ql-snow .ql-picker-options .ql-picker-item {
    cursor: pointer !important;
    display: block !important;
    padding-bottom: 5px !important;
    padding-top: 5px !important;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-label {
    color: #ccc !important;
    z-index: 2 !important;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-fill {
    fill: #ccc !important;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-label .ql-stroke {
    stroke: #ccc !important;
  }
  .ql-snow .ql-picker.ql-expanded .ql-picker-options {
    display: block !important;
    margin-top: -1px !important;
    top: 100% !important;
    z-index: 1 !important;
  }
  .ql-snow .ql-color-picker,
  .ql-snow .ql-icon-picker {
    width: 28px !important;
  }
  .ql-snow .ql-color-picker .ql-picker-label,
  .ql-snow .ql-icon-picker .ql-picker-label {
    padding: 2px 4px !important;
  }
  .ql-snow .ql-color-picker .ql-picker-label svg,
  .ql-snow .ql-icon-picker .ql-picker-label svg {
    right: 4px !important;
  }
  .ql-snow .ql-icon-picker .ql-picker-options {
    padding: 4px 0px !important;
  }
  .ql-snow .ql-icon-picker .ql-picker-item {
    height: 24px !important;
    width: 24px !important;
    padding: 2px 4px !important;
  }
  .ql-snow .ql-color-picker .ql-picker-options {
    padding: 3px 5px !important;
    width: 152px !important;
  }
  .ql-snow .ql-color-picker .ql-picker-item {
    border: 1px solid transparent !important;
    float: left !important;
    height: 16px !important;
    margin: 2px !important;
    padding: 0px !important;
    width: 16px !important;
  }
  .ql-snow .ql-picker:not(.ql-color-picker):not(.ql-icon-picker) svg {
    position: absolute !important;
    margin-top: -9px !important;
    right: 0 !important;
    top: 50% !important;
    width: 18px !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-label]:not([data-label=''])::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-label]:not([data-label=''])::before {
    content: attr(data-label) !important;
  }
  .ql-snow .ql-picker.ql-header {
    width: 98px !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: 'Normal' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: 'Heading 1' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: 'Heading 2' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: 'Heading 3' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: 'Heading 4' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: 'Heading 5' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: 'Heading 6' !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    font-size: 2em !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    font-size: 1.5em !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    font-size: 1.17em !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    font-size: 1em !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    font-size: 0.83em !important;
  }
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    font-size: 0.67em !important;
  }
  .ql-snow .ql-picker.ql-font {
    width: 108px !important;
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: 'Sans Serif' !important;
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: 'Serif' !important;
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: 'Monospace' !important;
  }
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    font-family: Georgia, Times New Roman, serif !important;
  }
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    font-family: Monaco, Courier New, monospace !important;
  }
  .ql-snow .ql-picker.ql-size {
    width: 98px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: 'Normal' !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: 'Small' !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: 'Large' !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: 'Huge' !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    font-size: 10px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    font-size: 18px !important;
  }
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    font-size: 32px !important;
  }
  .ql-snow .ql-color-picker.ql-background .ql-picker-item {
    background-color: #fff !important;
  }
  .ql-snow .ql-color-picker.ql-color .ql-picker-item {
    background-color: #000 !important;
  }
  .ql-toolbar.ql-snow {
    border: 1px solid #ccc !important;
    box-sizing: border-box !important;
    font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif !important;
    padding: 8px !important;
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 15px !important;
  }
  .ql-toolbar.ql-snow .ql-picker-label {
    border: 1px solid transparent !important;
  }
  .ql-toolbar.ql-snow .ql-picker-options {
    border: 1px solid transparent !important;
    box-shadow: rgba(0,0,0,0.2) 0 2px 8px !important;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: #ccc !important;
  }
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: #ccc !important;
  }
  .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item.ql-selected,
  .ql-toolbar.ql-snow .ql-color-picker .ql-picker-item:hover {
    border-color: #000 !important;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 0px !important;
  }
  .ql-snow .ql-tooltip {
    background-color: #fff !important;
    border: 1px solid #ccc !important;
    box-shadow: 0px 0px 5px #ddd !important;
    color: #444 !important;
    padding: 5px 12px !important;
    white-space: nowrap !important;
  }
  .ql-snow .ql-tooltip::before {
    content: "Visit URL:" !important;
    line-height: 26px !important;
    margin-right: 8px !important;
  }
  .ql-snow .ql-tooltip input[type=text] {
    display: none !important;
    border: 1px solid #ccc !important;
    font-size: 13px !important;
    height: 26px !important;
    margin: 0px !important;
    padding: 3px 5px !important;
    width: 170px !important;
  }
  .ql-snow .ql-tooltip a.ql-preview {
    display: inline-block !important;
    max-width: 200px !important;
    overflow-x: hidden !important;
    text-overflow: ellipsis !important;
    vertical-align: top !important;
  }
  .ql-snow .ql-tooltip a.ql-action::after {
    border-right: 1px solid #ccc !important;
    content: 'Edit' !important;
    margin-left: 16px !important;
    padding-right: 8px !important;
  }
  .ql-snow .ql-tooltip a.ql-remove::before {
    content: 'Remove' !important;
    margin-left: 8px !important;
  }
  .ql-snow .ql-tooltip a {
    line-height: 26px !important;
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-preview,
  .ql-snow .ql-tooltip.ql-editing a.ql-remove {
    display: none !important;
  }
  .ql-snow .ql-tooltip.ql-editing input[type=text] {
    display: inline-block !important;
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px !important;
    content: 'Save' !important;
    padding-right: 0px !important;
  }
  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "Enter link:" !important;
  }
  .ql-snow .ql-tooltip[data-mode=formula]::before {
    content: "Enter formula:" !important;
  }
  .ql-snow .ql-tooltip[data-mode=video]::before {
    content: "Enter video:" !important;
  }
  .ql-snow a {
    color: #06c !important;
  }
  .ql-container.ql-snow {
    border: 1px solid #ccc !important;
  }
  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-left {
    text-align: left;
  }