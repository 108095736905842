.SearchWrap {
    width: 100%;
    border: 1px solid #FF720A;
}
.SearchWrap input {
    width: calc(100% - 100px);
    font-size: 16px;
    padding: 20px 20px;
    box-sizing: border-box;
    border: none;
}
.SearchWrap input::placeholder {
    color: #FFC9A2;
}
.SearchWrap input:focus {
    outline: none;
}

.SearchWrap button {
    width: 100px;
    position:relative;
    font-size: 16px;
    color: #FF720A;
    cursor: pointer;
}
.SearchWrap button::before {
    position:absolute;
    left: 0;
    top: 3px;
    content: "";
    width: 1px;
    height: 20px;
    background-color: #FF720A;
}