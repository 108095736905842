.VideoDoc .Section01 {
    padding: 30px 0 15px;
    border-bottom: 1px solid #FF721A;
}
.VideoDoc .sectionTitle {
    font-size: 16px;
    color : #FF721A;
    font-weight: 600;
    text-align: left;
    margin-bottom: 30px;
}
.VideoDoc .videoTitle {
    font-size: 24px;
    color : #161616;
    font-weight: 600;
    text-align: left;
    margin-bottom: 30px;
}
.VideoDoc .SectionAdmin {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 20px 0;
}
.VideoDoc .SectionAdmin button {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
}
.VideoDoc .createdAt {
    font-size: 12px;
    color : #191919;
    text-align: right;
}
.VideoDoc .videoWrap {
    margin: 80px 0;
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
.VideoDoc .videoWrap::before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
    padding-top: 56.25%;

}
.VideoDoc .videoWrap .video{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.VideoDoc .videoWrap .video iframe{
    width: 100%;
    height: 100%;
}