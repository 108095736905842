#PostDoc .CategoryWrap{
    display: none;
}
.PostDoc {
    width: 100%;
    padding: 0 10px;
    box-sizing: border-box;
}
.PostDoc .Section01 {
    padding: 30px 0 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.PostDoc .sectionTitle {
    font-size: 16px;
    color : #FF721A;
    font-weight: 600;
}
.PostDoc .categoryTitle {
    font-size: 16px;
    color : #818181;
}


.PostDoc .Section02 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-bottom: 1px solid #FF721A;
    padding-bottom: 10px;
}
.PostDoc .Section02 h2 {
    margin-bottom: 15px;
    font-size: 20px;
    color : #000;
    text-align: left;
    line-height: 1.5em;
}
.PostDoc .Section02 .editor {
    margin-bottom: 15px;
    font-size: 14px;
    color : #818181;
}
.PostDoc .Section02 .createdAt {
    font-size: 12px;
    color : #191919;
    text-align: right;
}
.PostDoc .Section02 .editDate {
    width: 100%;
}
.PostDoc .SectionAdmin {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    padding: 20px 0;
}
.PostDoc .SectionAdmin button {
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
}
.PostDoc .Section03 {
    padding: 80px 0;
    width: 100%;
    min-height: 200px;
    text-align: initial;
    line-height: 1.5em;
}
.PostDoc .Section03 img {
    max-width: 100%;
}

