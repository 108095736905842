
.About .GroupImgWrap {
    width: 100%;
}
.About .GroupImgWrap img {
    width: 100%;
}

.Memberlist{
  width: 100%;
  display: flex;
  justify-content:space-between;
  margin-top: 25px;
}


.Members{
  width: 95%;
  margin: 0 auto;
  margin-bottom: 20px;
}
.Members p{
  font-size: 16px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 7px;
}
.Members p img{
  width: 80%;
}
.Members span{
  font-size: 14px;
  color: #818181;
}