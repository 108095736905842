#Header {
    width: 100%;
    height: 80px;
}

#Header .container {
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}
#Header .Logo {
    margin-left: 0;
    cursor: pointer;
}
#Header .Logo img {
    width: 200px;
    cursor: pointer;
}
#Header h2 {
    margin: 30px 0;
}
#Header a {
    margin-left: 30px;
    font-weight: 600;
}
#Header .sideBarBtn {
    display: none;
}

@media ( max-width : 768px ) {
    #Header {
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #ededed;
        position: relative;
    }
    #Header .container {
        height: 100%;
        align-items: center;
        justify-content: center;
    }
    #Header .Logo {
        display: block;
        text-align: center;
    }
    #Header h2 {
        margin: 0;
    }
    #Header .menu { 
        display: none;
    }
    #Header .sideBarBtn {
        font-size: 26px;
        font-weight: 800;
        display: block;
        position: absolute;
        left: 30px;
        top: 50%;
        color: #ff9100;
        transform: translate(-50%, -50%);
    }
    
}