.footer {
    display: block;
    width: 100%;
    margin: 0 auto;
    margin-top: 70px;
    padding: 35px 0;
    /* height: 245px; */
    background-color: #f5f5f5;
    text-align: left;
    color: #818181;
    font-size: 14px;
    line-height: 1.4;
}
.footer .Hr hr{
    display: none;
}
.footer h5 {
    font-size: 14px;
    margin-bottom: 15px;
}
.footer p {
    font-size: 14px;
    margin-bottom: 5px;
}