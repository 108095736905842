@font-face {
  font-family: 'NanumSquareR';
  src: url('./assets/fonts/NanumSquareR.otf');
}
.App {
  text-align: center;
  font-family: 'NanumSquareR';
  position: relative;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
* {
	margin: 0;
	padding: 0;
}
ul,
ol,
li {
	list-style: none;
}
a {
  color: #000;
}
a:link,
a:visited,
a:active {
	color: #222328;
	text-decoration: none;
  cursor: pointer;
}
a:hover{
  cursor: pointer;
}

button {
  background-color: transparent;;
  border: 0px solid #000;
}

.container {
  width: 100%;
  max-width: 1000px;
  padding: 0 60px;
  box-sizing: border-box;
  margin: auto;
}

.BoardName {
  font-size: 26px;
  text-align: left;
  margin: 30px 20px;
}
.AdminWriteBtn {
  float: right;
  font-size: 16px;
  padding: 10px 20px;
  background-color: #ff9100;
  color: #FFF;
  margin-right: 20px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;
}
.AdminWriteBtn:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.boardSearchWrap {
  width: 100%;
  padding: 30px 0 80px;
}
.boardSearchInput {
  padding: 10px 20px;
  width: 300px;
  border-radius: 5px;
  border: 1px solid #ff9100;
  font-size: 16px;
  margin-right: 15px;
}
.boardSearchBtn {
  padding: 10px 20px;
  border-radius: 5px;
  border: 1px solid #ff9100;
  background-color: #ff9100;;
  color: #FFF;
  font-size: 16px;
  cursor: pointer;
}
button {
  cursor: pointer;
}
button:hover {
  opacity: 0.7;
  transition: all 0.3s;
}
.orange {
  color: #ff9100;
}
.ql-editor{
  min-height:500px;
}
select {
  -webkit-appearance:none;
  -moz-appearance:none;
  appearance:none;
}

select::-ms-expand{
  display:none;
}
select {
  background:url('./assets/images/arrowDown.png') no-repeat 98% 50%/15px auto;
}
.bold {
  font-weight: 800;
}
.NewIcon {
  display: inline-block;
  padding: 2px 7px;
  background-color: #ff9100;
  border-radius: 10px;
  font-size: 10px;
  font-weight: 600;
  color: #FFF;
  margin-left: 10px;
}

.ql-align-right {
	text-align: right;
}
.ql-align-center {
	text-align: center;
}
.ql-align-left {
	text-align: left;
}
.CasesEditor .ql-toolbar.ql-snow {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #ccc;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
}
.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border-top: 1px solid #ccc !important;
}

@media (max-width : 768px) {
  .container {
    width: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin: auto;
  }
  .mb_dn {
    display: none;
  }
  .boardSearchWrap {
    display: flex;
  }
  .boardSearchInput {
    width: calc(100% - 95px);
  }
  .boardSearchBtn {
    width: 80px;
  }
  .AdminWriteBtn {
    margin-right: 0px;
  }
  .CounselEditor .ql-toolbar {
    display: none !important;
  }
  .CasesEditor .ql-toolbar.ql-snow {
    display: none !important;
  }
  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-top: 1px solid #ccc !important;
  }
  .PostContent_PostDocContents__9MQL6 {
    padding: 0 !important;
  }
  .PostContent_PostDocContents__9MQL6 img {
    max-width: 100%;
  }
}