.Login .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
}
.Login .container h2 {
    font-size: 24px;
    font-weight: 600;
    color: #FF720A;
    margin-bottom: 30px;
}
.Login .container label {
    font-size: 18px;
    font-weight: 600;
    color: #FF720A;
    margin-bottom: 10px;
}
.Login .container input {
    width: 200px;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #FF720A;
    margin-bottom: 40px;
}
.Login .container button {
    font-size: 16px;
    font-weight: 600;
    padding: 10px 20px;
    border-radius: 5px;
    border: 1px solid #FF720A;
    background-color: #FF720A;
    color: #fff;
    margin-bottom: 40px;
}