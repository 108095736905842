.Introduce {
    width: 100%;
    position: relative;
    border-bottom: 1px solid #DBDBDB;
}
.Introduce .ControlBar {
    width: 100%;
    display: flex;
}
.Introduce .ControlBar > div {
    width: 50%;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    border-top: 1px solid #DBDBDB;
    background-color: #EDEDED
}
.Introduce .Contents {
    min-height: 200px;
}
.Active {
    background-color: #FFF !important;
}
.Introduce .newsLists {
    padding-top: 30px;
}
.Introduce .recordLists {
    padding: 15px 0;
}
.Introduce .newsList {
    width: 100%;
    text-align: left;
    margin: 15px 0;
}
.Introduce .newsHead {
    width: 100%;
    display: flex;
    margin-bottom: 5px;
}
.Introduce .newsHead p {
    font-size: 10px;
    margin-right: 5px;
    color: #9D9D9D;
}
.Introduce .newsBody {
    font-size: 14px;
    color: #000000;
    cursor: pointer;
}
.Introduce .newsBody:hover {
    color: #FF720A;
}
.Introduce .recordList {
    display: flex;
    margin: 15px 0;

}
.Introduce .recordYear {
    font-size: 14px;
    color: #818181;
    margin-right: 10px;
}
.Introduce .recordContent {
    font-size: 14px;
    color: #000000;
}
.moreBtn {
    font-size: 14px;
    color: #FF720A;
    position: absolute;
    bottom: 7px;
    right: 10px;
    cursor: pointer;
}
.closeBtn {
    font-size: 14px;
    color: #FF720A;
    position: absolute;
    bottom: 7px;
    right: 10px;
    cursor: pointer;
}