.MobileMenu {
    display: none;
}
@media (max-width: 768px) {
    .MobileMenu {
        display: flex;
        width: 100%;
        height: 70px;
        position: fixed;
        bottom: 0;
        left: 0;
        background-color: #ff9100;
        z-index: 99;
    }
    .MobileMenu > div {
        width: 50%;
        height: 100%;
    }
    .MobileMenu > div:first-child {
        border-right: 1px solid #FFF;
    }
    .MobileMenu > div a {
        text-align: center;
        vertical-align: middle;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 20px;
        font-weight: 600;
        color: #FFF;
    }
    .MobileMenu > div > a > p{
        margin-left: 10px;
    }
}